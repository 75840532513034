import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

export default function BuyCarbonCredite() {
  const [apiData, setApiData] = useState(null);
  const [projectFields, setProjectFields] = useState(null);
  const listRef = useRef(null);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("scroll"))
      listRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://office.hempcarbonstandard.org/projectdetail/project-website/"
      )
      .then((d) => {
        setApiData(d.data);
      })
      .catch(() => {
        console.log("error fetching data.");
      });

    axios
      .get(
        "https://office.hempcarbonstandard.org/projectdetail/project-fields-website/"
      )
      .then((d) => {
        setProjectFields(d.data[0]);
      })
      .catch(() => {
        console.log("error fetching data.");
      });
  }, []);
  return (
    <div>
      <Helmet>
        <title>Carbon Removal Credits</title>
        <meta
          name="description"
          content="The Hemp Carbon Standard produces high integrity Carbon Removal Credits derived from a science based quantification methodology."
        />
        <meta
          name="keywords"
          content="Carbon Removal Credits, Buy Carbon Removal credits, Carbon Removal Credits USA, Buy Carbon Removal credits USA, Carbon Sequestration Credits, Buy Carbon Sequestration credits, 
          Carbon Removal Credits Europe, Buy Carbon Removal Credits, Carbon Removal Credits Canada, Buy Carbon Removal Credits Canada, 
          Carbon Removal Credits California, Carbon Removal Credits For Sale, Carbon Removal Credits Ukraine, Buy Carbon Removal Credits Ukraine."
        />
      </Helmet>

      <div className='bg-[url("../public/Group-561-1.jpg")] h-[240px] bg-center bg-cover'>
        <div className="flex justify-center items-center h-[240px] text-white">
          <p className="text-center font-bold md:font-extrabold text-[32px] md:text-[64px]">
            Buy Carbon Removal Credits
          </p>
        </div>
      </div>

      <div>
        <div className="flex flex-col md:flex-row md:w-[94%] md:ml-[3%] items-center md:pt-8 md:pb-4 font-medium">
          <p className="w-full px-8 md:px-10  py-2 text-[#139718] text-[32px] md:text-[39px]">
            Why Choose Hemp Carbon Credits?
          </p>
          <p className=" w-full px-8 md:px-10 py-2 italic">
            "These ground-breaking credits from the 2023 harvest, are based on a
            precise quantification methodology that harnesses remote sensing
            technology to accurately measure CO2 sequestration at scale", Carbon
            Herald.
          </p>
        </div>
        <div className="w-full md:w-[94%] ml-0 md:ml-[3%] py-2 md:py-6  flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-1/2 px-8 md:px-10">
            <ul className="list-disc md:leading-7">
              <li className="mb-2">
                <p>
                  Industrial hemp is a potent carbon removal agent, absorbing
                  C02, into its biomass, roots, and soil, thus helping mitigate
                  greenhouse gas emissions.
                </p>
              </li>
              <li className="mb-2">
                <p>
                  Acquiring Hemp Carbon Removal Credits boosts your company's
                  sustainability credentials, thus improving your corporate
                  reputation and brand value and aiding employee retention.
                </p>
              </li>
              <li className="mb-2">
                <p>
                  The Hemp Carbon Standard is the only organisation to use a
                  methodology that leverages Sentinel 2 satellite data and Net
                  Ecosystem Exchange (NEE) calculations, to accurately quantify
                  C02 sequestration from growing industrial hemp.
                </p>
              </li>
              <li className="mb-2">
                <p>
                  This process has been certified to ISO 14064-2 by Control
                  Union, ensuring Hemp Carbon Standard removal credits meet
                  international standards.
                </p>
              </li>
              <li className="mb-2">
                <p>
                  Investing in Carbon Removal Credits includes multiple benefits
                  including beyond value chain contribution to emissions,
                  potentially avoiding carbon taxes or penalties and attracting
                  socially conscious investors by positioning your business as
                  an environmentally responsible organisation.
                </p>
              </li>
            </ul>
            {/* <p className="mt-3 md:mt-0 md:leading-7">
              Companies should buy carbon credits from industrial hemp farming
              due to its significant carbon sequestration potential and
              sustainability benefits. Industrial hemp, a fast-growing plant,
              captures large quantities of CO2, more per unit of land than most
              plants, helping to combat climate change. By investing in these
              carbon credits, companies not only offset their own emissions, but
              also support a renewable resource that has a myriad of
              applications, from plastic substitutes to building materials,
              promoting circular economies.
            </p>
            <p className="mt-4 leading-7">
              Moreover, industrial hemp farming often requires fewer inputs like
              water and pesticides, making it an environmentally friendly
              agricultural choice. By buying these carbon credits, companies
              also demonstrate their commitment to environmental responsibility,
              enhancing their corporate image. This offers a competitive edge in
              a business landscape increasingly defined by sustainability.
              Buying carbon credits from hemp farming therefore presents a
              unique opportunity for companies to address their carbon footprint
              while supporting a sustainable and versatile industry.
            </p> */}
          </div>
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <img
              src="/New_home_page_image.png"
              alt="Carbon Removal Credits, Buy Carbon Removal Credits"
            />
          </div>
        </div>
      </div>
      <div ref={listRef}>
        {!apiData || !projectFields ? (
          <div className="h-[400px] flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 animate-spin"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </div>
        ) : (
          <div className="bg-[#f0f0f0] py-8 md:py-14" id="listings">
            <div className="grid grid-cols-1 md:grid-cols-3 w-[90%] ml-[5%] gap-x-8 gap-y-14">
              {apiData?.map((d) => (
                <div
                  key={d.id}
                  // justify-between
                  className="flex flex-col 
                 rounded-md border-8 border-solid  border-[#ffffff]
                 bg-[#fafafa] pb-8"
                >
                  <div className="h-[240pex] rounded-md">
                    <img
                      alt="Carbon Removal Credits, Buy Carbon Removal Credits"
                      src={d.picture_of_farm}
                      className="max-w-[100%] max-h-[100%] rounded-md"
                    />
                  </div>
                  <p className="font-bold mt-2 px-2">{d.farm}</p>
                  <div className="mt-2  px-2 flex items-start bg-[reed]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 mt-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                        />
                      </svg>
                      <p className="text-[14px] pl-1 pr-2 ">
                        {projectFields.standard} / {projectFields.validator}
                      </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 mt-0.5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>
                    <p className="text-[14px] pl-1 pr-2">{d.country}</p>
                  </div>
                  <p
                    className="mt-4 text-[14px] whitespace-pre-line px-2"
                    style={{
                      overflow: "hidden",
                      display: " -webkit-box",
                      WebkitLineClamp: "8",
                      lineClamp: "8",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {d.about_project}
                  </p>
                  <Link to={`/detail?id=${d.id}`} className="w-[220px]">
                    <p
                      className="bg-green-600 hover:bg-green-700 mt-6 ml-2 text-white py-2 
                    rounded-md text-center"
                    >
                      Learn More & Buy Credits
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
