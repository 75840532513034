import React, { useState, useContext } from "react";
import HeaderMenu from "./headerMenu";
import SideMenu from "./sideMenu";
import CheckMobileHook480 from "./checkMobileHook";
import { Link } from "react-router-dom";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";
import CheckOutContext from "../context/checkoutContext";

export default function Layout(props) {
  const isMobile = CheckMobileHook480();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const checkoutState = useContext(CheckOutContext);

  console.log("cccc", checkoutState?.openChat);

  const toggelNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <div className="w-full overflow-hidden">
      <header
        className="flex justify-between items-center h-[80px] md:h-[110px] 
         px-4 md:px-[30px] md:py-[17px]"
      >
        <div className="ml-0 md:ml-8 w-[130px] h-[45px] md:w-[175px] md:h-[58px]">
          <Link to="/">
            <img
              src="/logo.png"
              alt="Carbon Removal Credits, Buy Carbon Removal Credits"
              className="max-h-[100%] max-w-[100%]"
            />
          </Link>
        </div>
        {!isMobile ? (
          <HeaderMenu />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1.5"
            onClick={toggelNavbar}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}

        {isMobile && (
          <div
            className={`${
              !navbarOpen
                ? "translate-x-full"
                : "translate-x-0 shadow-[-50px_0px_100px_0px_rgba(0,0,0,.6)]"
            } right-0 w-[280px] min-h-screen top-0  z-50 fixed transition duration-300 `}
          >
            <SideMenu toggelNavbar={toggelNavbar} />
          </div>
        )}
      </header>
      <div className="min-h-screen">
        <>
          {props.children}
          <LiveChatWidget
            license="15694041"
            visibility={checkoutState.openChat ? "maximized" : "minimized"}
            onVisibilityChanged={(e) => {
              if (e.visibility === "minimized") {
                checkoutState.setOpenChat(false);
              }
            }}
          />
        </>
      </div>
      <footer className="md:h-[420px] bg-[#11170f] text-[#fff] pb-10 md:pb-0">
        <div className="pt-[90px] flex flex-col md:flex-row justify-around items-center">
          <div className="md:w-[18%] text-center md:text-left px-8 md:px-0 py-6 md:py-0">
            <p className="text-[19px] font-bold">Hemp Carbon Standard</p>
            <p className="mt-4 md:mt-8 text-[17px] leading-7">
              World’s leading carbon platform for industrial hemp farming
            </p>
          </div>
          <div className="md:w-[15%] text-center md:text-left px-8 md:px-0 py-6 md:py-0">
            <p className="text-[19px] font-bold"> Office </p>
            <p className="mt-4 md:mt-8 text-[17px]">United Kingdom –</p>
            <p className="mt-1 text-[17px] leading-7">
              85 Great Portland Street London W1W 7LT
            </p>
          </div>
          <div className="md:w-[10%] text-center md:text-left px-8 md:px-0 py-6 md:py-0">
            <p className="text-[19px] font-bold">Links</p>
            <ul className="mt-4 md:mt-8">
              <li className="pb-1">
                <a href="https://hempcarbonstandard.org/">
                  <p>Home</p>
                </a>
              </li>
              <li className="py-1">
                <a href="https://hempcarbonstandard.org/about/">
                  <p>About</p>
                </a>
              </li>
              <li className="py-1">
                <a href="https://hempcarbonstandard.org/technology/">
                  <p>Technology</p>
                </a>
              </li>
              <li className="py-1">
                <a href="https://hempcarbonstandard.org/contacts/">
                  <p>Contacts</p>
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="w-[10%]">
            <p className="text-[19px] font-bold"> Get in Touch</p>
          </div> */}
        </div>
        <div className="px-4 mt-20 text-center">
          <p>HempCarbonStandard © 2023. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
}
