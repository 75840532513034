import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import CheckOutContext from "../context/checkoutContext";
import { useNavigate } from "react-router-dom";
import NotificationBox from "../Layout/notificationBox";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Detail() {
  const [apiData, setApiData] = useState(null);
  const [projectFields, setProjectFields] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  let forwardReff = useRef();
  let actualRef = useRef();

  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState(null);
  const [notificationTitle, setNotificationTitle] = useState(null);
  const [notificationBody, setNotificationBody] = useState(null);

  const [first, setFirst] = useState(null);
  const [second, setSecond] = useState(null);

  // const [selected, setSelected] = useState("future");
  const [selected, setSelected] = useState("exact");
  // exact

  const checkoutStatee = useContext(CheckOutContext);

  const [quantity, setQuantity] = useState(null);

  const [stock, setStock] = useState(null);
  const [price, setPrice] = useState(null);

  const [loadingStock, setLoadingStock] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);

  const shownotiftion = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  useEffect(() => {
    const id = searchParams.get("id");
    setLoadingStock(true);
    setLoadingPrice(true);
    axios
      .get(
        `https://office.hempcarbonstandard.org/projectdetail/all-project-website/?id=${id}`
      )
      .then((d) => {
        setApiData(d.data[0]);
      })
      .catch(() => {
        console.log("error fetching data.");
      });

    axios
      .get(
        "https://office.hempcarbonstandard.org/projectdetail/project-fields-website/"
      )
      .then((d) => {
        setProjectFields(d.data[0]);
      })
      .catch(() => {
        console.log("error fetching data.");
      });

    axios
      .get("https://office.hempcarbonstandard.org/projectdetail/stocks/")
      .then((d) => {
        setStock(d.data);
        setLoadingStock(false);
      })
      .catch(() => {
        console.log("error on fetching stock");
      });

    axios
      .get("https://office.hempcarbonstandard.org/projectdetail/price/")
      .then((d) => {
        setPrice(d.data);
        setLoadingPrice(false);
      })
      .catch(() => {
        console.log("error on fetching stock");
      });
  }, []);

  useEffect(() => {
    if (price && stock) {
      const id = searchParams.get("id");
      const actualAvailable = stock[0]?.actual_in - stock[0]?.actual_out;
      const forwardAvailable = stock[0]?.farward_in - stock[0]?.farward_out;
      checkoutStatee.update({
        ...checkoutStatee.checkout,
        type: "future",
        forwardPrice: price[0]?.forward_credit_price,
        actualPrice: price[0]?.actual_credit_price,
        actualAvailableQty: actualAvailable,
        forwardAvailableQty: forwardAvailable,
        projectId: id,
      });
    }
  }, [price, stock]);

  useEffect(() => {
    // const xa =
    //   projectFields?.impacts_on_carbon_markets?.search("own emissions.");
    const first = projectFields?.impacts_on_carbon_markets.slice(0, 829);
    setFirst(first);

    // const xb = projectFields?.impacts_on_carbon_markets?.search(
    //   "The sequestration potential"
    // );
    // const xc =
    //   projectFields?.impacts_on_carbon_markets?.search("contributions.");

    const second = projectFields?.impacts_on_carbon_markets.slice(831, 1797);
    setSecond(second);
  }, [projectFields]);

  const buy = () => {
    const actualAvailable = stock[0]?.actual_in - stock[0]?.actual_out;
    const forwardAvailable = stock[0]?.farward_in - stock[0]?.farward_out;
    if (!quantity) {
      setNotificationTitle("Error !!");
      setNotificationBody("Input quantity to proceed.");
      setNotificationType("error");
      shownotiftion();
    } else {
      if (selected === "future") {
        if (forwardAvailable < quantity) {
          setNotificationTitle("Error !!");
          setNotificationBody(
            "Selected Quantity is more that total forward quantity."
          );
          setNotificationType("error");
          shownotiftion();
        } else {
          navigate("/confirm");
        }
      } else {
        if (actualAvailable < quantity) {
          setNotificationTitle("Error !!");
          setNotificationBody(
            "Selected Quantity is more that total actual quantity."
          );
          setNotificationType("error");
          shownotiftion();
        } else {
          navigate("/confirm");
        }
      }
    }
  };

  return (
    <>
      <div
        className={`${
          !showNotification
            ? "translate-x-full"
            : "translate-x-0 dark:shadow-[-60px_20px_100px_4px_rgba(0,0,0,.99)] \
            shadow-[-40px_10px_100px_4px_rgba(0,0,0,.3)]"
        } 
             right-0 w-[280px] md:w-[310px] 2xl:w-[320px] z-20
             top-4 fixed rounded-lg
              transition duration-300 `}
      >
        <NotificationBox
          title={notificationTitle}
          body={notificationBody}
          setShowNotification={setShowNotification}
          type={notificationType}
        />
      </div>
      <Helmet>
        <title>{`${apiData?.farm} - ${apiData?.country}`}</title>
        <meta name="description" content={`${apiData?.about_project}`} />
        <meta
          name="keywords"
          content="Carbon Removal Credits USA, Buy Carbon Removal credits USA, Carbon Sequestration Credits, Buy Carbon Sequestration credits, 
          Carbon Removal Credits Europe, Buy Carbon Removal Credits, Carbon Removal Credits Canada, Buy Carbon Removal Credits Canada, 
          Carbon Removal Credits California, Carbon Removal Credits For Sale, Carbon Removal Credits Ukraine, Buy Carbon Removal Credits Ukraine."
        />
      </Helmet>
      <div className=" bg-[#f0f0f0] py-6 md:py-10">
        <Link
          to={`/?scroll=${true}`}
          className="w-[130px] md:w-[150px]  ml-[5%] flex h-[30px]"
        >
          <div className="flex h-[30px] items-center font-semibold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="#15803d"
              className="w-4 h-4 md:w-4 md:h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>

            <p className="text-green-700 ml-1 text-[14px] md:text-[16px] mt-0 md:mt-[-1px] p-0 m-0 bg-[reds] text-[18pdx]">
              Back to listings
            </p>
          </div>
        </Link>

        <div className="flex flex-col-reverse md:flex-row w-[90%] ml-[5%] mt-4">
          <div className="w-full md:w-[65%] rounded-md bg-[#fff] pb-10 mt-8 md:mt-0">
            <div className="image relative">
              <img
                src={apiData?.picture_of_farm}
                alt="Carbon Removal Credits, Buy Carbon Removal Credits"
                className="max-w-[100%] max-h-[100%] rounded-md"
              />
              <div
                className="absolute bottom-0 z-40 text-white w-[100%] h-[100%] flex flex-col
                  bg-[#000] opacity-25 "
              />
              <div className="absolute bottom-4 z-50 text-white w-[100%] flex flex-col">
                <p className="pl-4 font-bold text-[22px]">{apiData?.farm}</p>
                <div className="mt-2  px-4 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                  </svg>
                  <p className="text-[14px] pl-1 pr-2">
                    {projectFields?.standard} / {projectFields?.validator}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>
                  <p className="text-[14px] pl-1 pr-2">{apiData?.country}</p>
                </div>
              </div>
            </div>
            <div className="mt-6 px-4">
              <p className="font-semibold">About the Project - </p>
              <p className="mt-2 leading-7">{apiData?.about_project}</p>
            </div>
          </div>

          {/* <div className=" rounded-md ml-0 md:ml-[2%]  w-full md:w-[33%] bg-[red]">
            <div className="bg-[green] w-full flex justify-center">
              <p>Our Partner</p>
            </div>
            <div className="flex-col">

            </div>
          </div> */}

          {/* bg-[#fff] */}
          <div className=" rounded-md ml-0 md:ml-[2%]  w-full md:w-[33%] bg-[redd]">
            <div className="btnContainer flex justify-between px-4 py-6 md:py-7 h-[15%] bg-[pidnk]">
              <button
                className={` ${
                  selected === "future"
                    ? "hover:text-green-800 text-green-700 shadow-[0px_1px_3px_0px_#73d13d] \
                    font-semibold"
                    : "text-[#8c8c8c] hover:text-[#000] shadow"
                } 
                 w-[48%] h-[45px] rounded-md text-center px-2 `}
                onClick={() => {
                  setSelected("future");
                  setQuantity(null);
                  checkoutStatee.update({
                    ...checkoutStatee.checkout,
                    type: "future",
                    purchaseQty: null,
                  });
                  if (selected === "exact") actualRef.current.value = "";
                  // actualRef.current.value = "";
                }}
              >
                Forward Credits
              </button>
              <button
                className={` ${
                  selected === "exact"
                    ? "hover:text-green-700 text-green-600 shadow-[0px_1px_3px_0px_#73d13d] font-semibold"
                    : "text-[#8c8c8c] hover:text-[#000] shadow"
                } 
               w-[48%] h-[45px] rounded-md text-center px-2 `}
                onClick={() => {
                  setSelected("exact");
                  setQuantity(null);
                  checkoutStatee.update({
                    ...checkoutStatee.checkout,
                    type: "exact",
                    purchaseQty: null,
                  });
                  if (selected === "future") forwardReff.current.value = "";
                }}
              >
                Credits
              </button>
            </div>
            <div className="btnContainer h-[85%] bg-[oraange] flex justify-between px-4 pb-2">
              {loadingStock || loadingPrice ? (
                <div className="w-[100%] h-[300px] flex flex-col justify-center items-center">
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-5 h-5 animate-spin"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                    <small>Loading Stocks ...</small>
                  </>
                </div>
              ) : selected === "future" ? (
                <div className="h-[100%] flex flex-col justify-between">
                  <div className="w-[100%]  flex flex-col text-left pb-8 md:pb-0">
                    <div className="text-[14px] pl-4">
                      <p className="mt-4">
                        Available Forward Credits:{" "}
                        <span className="font-semibold">
                          {stock &&
                            stock[0]?.farward_in - stock[0]?.farward_out}
                        </span>
                      </p>
                      <p className="mt-4">
                        Price per tonne:{" "}
                        <span className="font-semibold">
                          ${price && price[0]?.forward_credit_price}
                        </span>
                      </p>
                    </div>
                    <div className="pl-4 text-[14px] mt-4 flex justify-start items-end">
                      <p className="w-[50%]">
                        Amount of Forward Credits required (tonnes):{" "}
                      </p>
                      <input
                        type="number"
                        id="forward"
                        ref={forwardReff}
                        className="border-[#1cbe59] ml-[5%] w-[45%] rounded border text-sm h-7 px-2 outline-none m-0"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                        }
                        placeholder="quantity in tonnes"
                        style={{
                          appearance: "none",
                          WebkitAppearance: "none",
                          MozAppearanc: "none",
                          maring: 0,
                        }}
                        onChange={(e) => {
                          setQuantity(e.target.value);

                          checkoutStatee.update({
                            ...checkoutStatee.checkout,
                            purchaseQty: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="px-4 mt-6">
                      <small className="font-bold">Forward Credits</small>
                      <br />
                      <small>
                        Buying forward credits allows buyers to lock in today’s
                        pricing for the future delivery of carbon credits. You
                        will be charged 50% of the total cost now, with the
                        balance due when the credits are validated and issued as
                        carbon certificates.
                      </small>
                    </div>

                    <button
                      className="bg-green-600 hover:bg-green-700 mt-10 ml-2 text-white py-1.5 
                      rounded-md text-center"
                      onClick={buy}
                    >
                      Buy Forward Credits
                    </button>
                  </div>

                  <div className="w-full h-[80%">
                    <img
                      src="/Trusted Carbon.png"
                      className="w-full h-[80%] mt-[20px]"
                    />
                  </div>

                  {/* <div className="w-full h-[80%]">
                    <img
                      src="/Trusted Carbon.png"
                      className="max-h-full max-w-full"
                    />
                  </div> */}

                  <div className="bg-yellow mb-[20px] pl-4">
                    <p
                      className="cursor-pointer w-[260px] py-1 font-bold text-[#096dd9] underline hover:text-[#0958d9]"
                      onClick={() => {
                        checkoutStatee.setOpenChat(true);
                      }}
                    >
                      Chat live with our sales advisors
                    </p>
                  </div>
                </div>
              ) : (
                <div className="h-[100%] flex flex-col justify-between">
                  <div className="w-[100%] flex flex-col text-left pb-8 md:pb-0">
                    <div className="text-[14px] pl-4">
                      <p className="mt-4">
                        Available Actual Credits:{" "}
                        <span className="font-semibold">
                          {stock && stock[0]?.actual_in - stock[0]?.actual_out}
                        </span>
                      </p>
                      <p className="mt-4">
                        Price per tonne:{" "}
                        <span className="font-semibold">
                          ${price && price[0]?.actual_credit_price}
                        </span>
                      </p>
                    </div>
                    <div className="pl-4 text-[14px] mt-4 flex justify-start items-end">
                      <p className="w-[40%]">
                        Amount of Credits required (tonnes):{" "}
                      </p>
                      <input
                        type="number"
                        ref={actualRef}
                        id="actual"
                        className=" border-[#1cbe59] ml-[15%] w-[45%] rounded border text-sm h-7 px-2 outline-none
                              m-0"
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                        }
                        placeholder="quantity in tonnes"
                        style={{
                          appearance: "none",
                          WebkitAppearance: "none",
                          MozAppearanc: "none",
                          maring: 0,
                        }}
                        onChange={(e) => {
                          setQuantity(e.target.value);
                          checkoutStatee.update({
                            ...checkoutStatee.checkout,
                            purchaseQty: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="pl-4 text-[14px] mt-4 flex justify-start items-end">
                      <p className="w-[40%]">Total: </p>
                      <input
                        value={`$ ${
                          price && price[0]?.actual_credit_price * quantity
                        }`}
                        disabled
                        id="actual"
                        className=" border-[#1cbe59] ml-[15%] w-[45%] rounded border text-sm h-7 px-2 outline-none
                              m-0"
                      />
                    </div>
                    <button
                      className="bg-green-600 hover:bg-green-700 mt-10 ml-2 text-white py-1.5 
                     rounded-md text-center"
                      onClick={buy}
                    >
                      Buy Credits
                    </button>
                  </div>

                  <div className="w-full h-[80%">
                    <img
                      src="/Trusted Carbon.png"
                      className="w-full h-[80%] mt-[20px]"
                    />
                  </div>

                  <div className="mb-[20px] pl-4">
                    <p
                      className="cursor-pointer w-[260px] py-1 font-bold text-[#096dd9] underline hover:text-[#0958d9]"
                      onClick={() => {
                        checkoutStatee.setOpenChat(true);
                      }}
                    >
                      Chat live with our sales advisors
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="second w-[90%] ml-[5%] px-4 mt-10 bg-white rounded-md flex flex-col
         md:flex-row-reverse justify-center md:justify-between items-center"
        >
          <div className="bg-[greeen] md:w-[45%] h-[60%] flex items-center gap-x-2 mt-8 md:mt-0">
            <div className="w-[33%]">
              <img
                src="/logo.png"
                alt="Carbon Removal Credits, Buy Carbon Removal Credits"
              />
            </div>
            <div className="w-[33%]">
              <img
                src="/control_union_logo_large.png.webp"
                alt="Carbon Removal Credits, Buy Carbon Removal Credits"
              />
            </div>
            <div className="w-[33%]">
              <img
                src="/ISO-Logo.jpg"
                alt="Carbon Removal Credits, Buy Carbon Removal Credits"
              />
            </div>
          </div>

          <div className="mt-4 md:mt-0 bg-[yelleow] md:w-[50%]">
            <p className="pt-6 pb-2 font-bold">
              Industrial Hemp Farming/Regenerative Agriculture
            </p>
            <p className="pt-2">
              <span className="font-semibold">Farm</span> - {apiData?.farm}
            </p>
            <p className="pt-2">
              <span className="font-semibold">Category</span> -{" "}
              {projectFields?.category}
            </p>
            <p className="pt-2">
              <span className="font-semibold"> Standard - </span>{" "}
              {projectFields?.standard}{" "}
            </p>
            <p className="pt-2">
              <span className="font-semibold">Project Type - </span>{" "}
              {projectFields?.project_type}
            </p>
            <p className="pt-2">
              <span className="font-semibold">Status - </span>{" "}
              {apiData?.status?.map((d) => (
                <span key={d.id}>{d.value}, </span>
              ))}
            </p>
            <p className="pt-2">
              <span className="font-semibold">Validator - </span>{" "}
              {projectFields?.validator}{" "}
            </p>
            <p className="pt-2">
              <span className="font-semibold">Location -</span>{" "}
              {apiData?.state + ", " + apiData?.country}
            </p>
            <p className="pt-2">
              <span className="font-semibold">
                {" "}
                Project Duration (Months) -{" "}
              </span>{" "}
              {apiData?.project_duration}
            </p>
            <p className="pt-2">
              <span className="font-semibold">Carbon Units Available - </span>
            </p>
            <p className="pt-2">
              <span className="font-semibold"> Expected Validation Date -</span>{" "}
              {apiData?.expected_validation_date}
            </p>
            <p className="pt-2">
              <span className="font-semibold"> Validation Completed - </span>{" "}
              {apiData?.validation_completed_date}
            </p>
            <p className="pt-2 pb-8 font-semibold"> Field Shape Files - </p>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-x-4">
              {apiData?.filenames.map((d) => (
                <div
                  key={d.id}
                  className="flex flex-col  items-center mb-8 bg-[#f5f5f5] rounded-md p-2"
                >
                  <img
                    src={d.img}
                    alt="Carbon Removal Credits, Buy Carbon Removal Credits"
                    className="rounded-md"
                  />
                  <p>{d.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="second w-[90%] ml-[5%] px-4 mt-10 pt-6 pb-10 bg-white rounded-md">
          <p className="pt-6 pb-2 font-bold">Impacts on Carbon Markets</p>
          <p className="mt-4 leading-7 whitespace-pre-line">{first}</p>
          <br />
          <span className="font-semibold">UN Report</span> -{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={projectFields?.un_report_link}
            className="text-[#1890ff]"
          >
            {projectFields?.un_report_link}
          </a>
          {/* </p> */}
          <p className="mt-6 leading-7 whitespace-pre-line">{second}</p>
        </div>
      </div>
    </>
  );
}
