import "./App.css";
import Layout from "./Layout/layout";
import Routess from "./Layout/routess";
import { BrowserRouter } from "react-router-dom";
import CheckoutState from "./context/checkoutState";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga4";

// ReactGA.initialize("G-EPD9QWBQX8");

const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: pathname + search,
    // });
  }, [pathname]);

  return null;
};

function App() {
  return (
    <CheckoutState>
      <BrowserRouter>
        <Layout>
          <ScrollToTop />
          <Routess />
        </Layout>
      </BrowserRouter>
    </CheckoutState>
  );
}

export default App;
