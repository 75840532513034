import { useState } from "react";
import CheckOutContext from "./checkoutContext";

const CheckoutState = (props) => {
  const state = {
    forwardPrice: null,
    actualPrice: null,
    forwardAvailableQty: null,
    actualAvailableQty: null,
    type: null,
    purchaseQty: null,
    projectId: null,
    // openChat: false,
  };
  const [checkout, setCheckout] = useState(state);

  const [openChat, setOpenChat] = useState(false);

  const update = (value) => {
    setCheckout(value);
  };
  return (
    <CheckOutContext.Provider
      value={{ openChat, setOpenChat, checkout, update }}
    >
      {props.children}
    </CheckOutContext.Provider>
  );
};

export default CheckoutState;
