import React from "react";

export default function SideMenu(props) {
  const { toggelNavbar } = props;

  return (
    <div className="py-2 bg-white z-50">
      <section
        className="h-[45px] 2xl:h-[50px] w-full flex justify-between px-4 items-baseline
             border-b-2 border-[#f0f0f0]"
      >
        <p className="bold text-2xl ">Menu</p>

        <button
          onClick={toggelNavbar}
          round="rounded-full"
          padd="p-1.5 2xl:p-2"
          arealabel="switch"
          rotate="hover:md:rotate-90"
          // className="flex justifu-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 md:h-6 md:w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </section>

      <ul
        className="h-[calc(100vh-45px)] 2xl:h-[calc(100vh-50px)]  space-y-2 2xl:space-y-3 
        overflow-y-auto
               pt-4 2xl:pt-3 pb-3 px-4 text-sm text-[16px] font-bold"
      >
        <li onClick={toggelNavbar}>
          <a href="https://hempcarbonstandard.org/">
            Home
            <span
              className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5
                bg-[#0A1108] group-active:max-w-full group-active:h-1 active:bg-[red] "
            />
          </a>
        </li>
        <li onClick={toggelNavbar}>
          <a href="https://hempcarbonstandard.org/about/">
            About
            <span className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5 bg-[#0A1108]" />
          </a>
        </li>

        <li onClick={toggelNavbar}>
          <a href="https://hempcarbonstandard.org/technology/">
            Technology
            <span className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5 bg-[#0A1108]" />
          </a>
        </li>
        <li onClick={toggelNavbar}>
          <a href="https://hempcarbonstandard.org/standards/">
            Standard
            <span className="block mt-1 max-w-0 group-hover:max-w-full transition-all  duration-150 h-0.5 bg-[#0A1108]" />
          </a>
        </li>
        <li onClick={toggelNavbar}>
          Buy Carbon Credits
          <span className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5 bg-[#0A1108]" />
        </li>
        <li onClick={toggelNavbar}>
          <a href="https://hempcarbonstandard.org/contacts/">
            Contacts
            <span className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5  bg-[#0A1108]" />
          </a>
        </li>
        <li onClick={toggelNavbar}>
          <a href="https://hempcarbonstandard.com/">
            Login
            <span className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5 bg-[#0A1108]" />
          </a>
        </li>
      </ul>
    </div>
  );
}
