import { useState, useContext, useEffect } from "react";

import NotificationBox from "../Layout/notificationBox";
import axios from "axios";
import CheckOutContext from "../context/checkoutContext";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Confirm() {
  const [checked, setChecked] = useState(false);
  const [fName, setFName] = useState(false);
  const [lName, setLName] = useState(false);
  const [email, setEmail] = useState(false);
  const [companyName, setCompanyName] = useState(false);
  const [state, setState] = useState(false);
  const [zip, setZip] = useState(false);
  const [country, setCountry] = useState(false);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState(null);
  const [notificationTitle, setNotificationTitle] = useState(null);
  const [notificationBody, setNotificationBody] = useState(null);

  const checkoutStatee = useContext(CheckOutContext);
  const [searchParams] = useSearchParams();

  const shownotiftion = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    if (searchParams.get("paymentSuccess")) {
      setNotificationTitle("Success !!");
      setNotificationBody("Payment Done.");
      setNotificationType("success");
      shownotiftion();

      if (searchParams.get("obj")) {
        const objID = searchParams.get("obj");
        axios
          .post(
            "https://office.hempcarbonstandard.org/projectdetail/after-confirmation/",
            {
              id: objID,
            }
          )
          .then(() => {
            console.log("updated");
          })
          .catch(() => {
            console.log("error");
          });
      }
    } else if (searchParams.get("paymentCanceled")) {
      setNotificationTitle("Error !!");
      setNotificationBody("Something went wrong.");
      setNotificationType("error");
      shownotiftion();
    }
  }, [searchParams]);

  const Submit = (e) => {
    e.preventDefault();

    if (!fName) {
      setNotificationTitle("Error !!");
      setNotificationBody("Input first name.");
      setNotificationType("error");
      shownotiftion();
      return;
    } else if (!lName) {
      setNotificationTitle("Error !!");
      setNotificationBody("Input last name.");
      setNotificationType("error");
      shownotiftion();
      return;
    } else if (!companyName) {
      setNotificationTitle("Error !!");
      setNotificationBody("Input company name.");
      setNotificationType("error");
      shownotiftion();
      return;
    } else if (!state) {
      setNotificationTitle("Error !!");
      setNotificationBody("Input state.");
      setNotificationType("error");
      shownotiftion();
      return;
    } else if (!zip) {
      setNotificationTitle("Error !!");
      setNotificationBody("Input zip.");
      setNotificationType("error");
      shownotiftion();
      return;
    } else if (!country) {
      setNotificationTitle("Error !!");
      setNotificationBody("Input country.");
      setNotificationType("error");
      shownotiftion();
    } else if (!email) {
      setNotificationTitle("Error !!");
      setNotificationBody("Input email.");
      setNotificationType("error");
      shownotiftion();
      return;
    } else if (!checked) {
      setNotificationTitle("Error !!");
      setNotificationBody("Accept Terms and Conditiona.");
      setNotificationType("error");
      shownotiftion();
      return;
    } else if (email) {
      if (!validateEmail(email)) {
        setNotificationTitle("Error !!");
        setNotificationBody("This is not a valid email.");
        setNotificationType("error");
        shownotiftion();
        return;
      }
    }

    axios
      .post(
        "https://office.hempcarbonstandard.org/projectdetail/create-checkout-session",
        {
          fname: fName,
          lname: lName,
          email: email,
          company: country,
          state: state,
          zip: zip,
          country: country,
          quantity: checkoutStatee.checkout.purchaseQty,
          project_id: checkoutStatee.checkout.projectId,
          type: checkoutStatee.checkout.type,
          price:
            checkoutStatee.checkout.type === "future"
              ? checkoutStatee.checkout.forwardPrice
              : checkoutStatee.checkout.actualPrice,
        }
      )
      .then((d) => {
        window.open(d.data.url, "_self");
      })
      .catch(() => {
        setNotificationTitle("Error !!");
        setNotificationBody("Something went wrong, try again.");
        setNotificationType("error");
        shownotiftion();
      });
  };

  return (
    <>
      <div
        className={`${
          !showNotification
            ? "translate-x-full"
            : "translate-x-0 dark:shadow-[-60px_20px_100px_4px_rgba(0,0,0,.99)] \
          shadow-[-40px_10px_100px_4px_rgba(0,0,0,.3)]"
        } 
           right-0 w-[280px] md:w-[310px] 2xl:w-[320px] z-20
           top-4 fixed rounded-lg
            transition duration-300 `}
      >
        <NotificationBox
          title={notificationTitle}
          body={notificationBody}
          setShowNotification={setShowNotification}
          type={notificationType}
        />
      </div>

      <Helmet>
        <title>Marketplace - Business Identity</title>
        <meta
          name="description"
          content="The Hemp Carbon Standard produces high integrity Carbon Removal Credits derived from a science based quantification methodology."
        />
        <meta
          name="keywords"
          content="Carbon Removal Credits USA, Buy Carbon Removal credits USA, Carbon Sequestration Credits, Buy Carbon Sequestration credits, 
          Carbon Removal Credits Europe, Buy Carbon Removal Credits, Carbon Removal Credits Canada, Buy Carbon Removal Credits Canada, 
          Carbon Removal Credits California, Carbon Removal Credits For Sale, Carbon Removal Credits Ukraine, Buy Carbon Removal Credits Ukraine."
        />
      </Helmet>

      <div className="w-full flex flex-col justify-center items-center mt-10 bg-[rded]">
        <form
          className="w-[90%] md:max-w-[45%]"
          onSubmit={(e) => {
            Submit(e);
          }}
        >
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="Jane"
                onChange={(e) => {
                  setFName(e.target.value);
                }}
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Last Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder="Doe"
                onChange={(e) => {
                  setLName(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Email
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                // type="email"
                placeholder="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Company Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                placeholder="company name"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                State / Region
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-city"
                type="text"
                placeholder="state"
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-zip"
              >
                Zip / Postal Code
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-zip"
                type="text"
                placeholder="zip-code"
                onChange={(e) => {
                  setZip(e.target.value);
                }}
              />
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-city"
              >
                Country
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-country"
                type="text"
                placeholder="country"
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mt-6 flex items-center">
            <p>
              I HAVE READ AND AGREE TO THE{" "}
              <Link to="/policy">
                <span className="text-[#1890ff]">BUYER FULFILLMENT POLICY</span>
              </Link>
            </p>
            <input
              checked={checked}
              id="checked-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 mx-2 text-blue-600 bg-gray-100 border-gray-300 rounded"
              // onClick={() => {
              //   setChecked(!checked);
              // }}
              onChange={() => {
                setChecked(!checked);
              }}
            />
          </div>
          <p className="mt-4 font-bold">* All sales are final</p>
          <button
            className="bg-green-600 w-[200px] hover:bg-green-700 mt-10 text-white py-1.5 
                 rounded-md text-center"
            type="submit"
          >
            Confirm & Continue
          </button>
        </form>
        <div className="w-[90%] md:w-[50%] mt-14 pb-10 md:pb-20 flex items-center justify-center gap-x-2">
          <div className="w-[25%]">
            <img src="/stripe.png" alt="logo" />
          </div>
          <div className="w-[25%]">
            <img src="/logo.png" alt="logo" />
          </div>
          <div className="w-[25%]">
            <img src="/control_union_logo_large.png.webp" alt="logo" />
          </div>
          <div className="w-[25%]">
            <img src="/ISO-Logo.jpg" alt="logo" />
          </div>
        </div>
      </div>
    </>
  );
}
