import React from "react";

export default function HeaderMenu() {
  return (
    <div className="">
      <ul className="flex flex-col md:flex-row mt-0 mr-10 space-x-7 text-sm text-[16px] font-bold">
        <li className="text-[15px] group text-[#0A1108] cursor-pointer">
          <a href="https://hempcarbonstandard.org/">
            Home
            <span
              className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5
         bg-[#0A1108] group-active:max-w-full group-active:h-1 active:bg-[red] "
            />
          </a>
        </li>
        <li className="text-[15px] group text-[#0A1108] cursor-pointer">
          <a href="https://hempcarbonstandard.org/about/">
            About
            <span
              className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5
         bg-[#0A1108]"
            />
          </a>
        </li>
        <li className="text-[15px] group text-[#0A1108] cursor-pointer">
          <a href="https://hempcarbonstandard.org/technology/">
            Technology
            <span
              className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5
         bg-[#0A1108]"
            />
          </a>
        </li>
        <li className="text-[15px] group text-[#0A1108] cursor-pointer">
          Buy Carbon Credits
          {/* <span
            className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5
         bg-[#0A1108]"
          /> */}
          <span
            className="block mt-1 max-w-full transition-all duration-150 h-0.5
           bg-[#0A1108]"
          />
        </li>
        <li className="text-[15px] group text-[#0A1108] cursor-pointer">
          <a href="https://hempcarbonstandard.org/standards/">
            Standard
            <span
              className="block mt-1 max-w-0 group-hover:max-w-full transition-all  duration-150 h-0.5
             bg-[#0A1108]"
            />
          </a>
        </li>
        <li className="text-[15px] group text-[#0A1108] cursor-pointer">
          <a href="https://hempcarbonstandard.org/contacts/">
            Contacts
            <span
              className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5
         bg-[#0A1108]"
            />
          </a>
        </li>
        <li className="text-[15px] group text-[#0A1108] cursor-pointer">
          <a href="https://hempcarbonstandard.com/">
            Login
            <span
              className="block mt-1 max-w-0 group-hover:max-w-full transition-all duration-150 h-0.5
         bg-[#0A1108]"
            />
          </a>
        </li>
      </ul>
    </div>
  );
}
