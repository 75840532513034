import React from "react";

export default function Policy() {
  return (
    <div className="w-[80%] ml-[10%] mt-10 pb-16">
      <p className="font-bold">Hemp Carbon Standard Fulfillment Policy</p>
      <br />

      <p className="font-bold mt-1">Buyer Policy</p>
      <p className="mt-1">
        It is a mandatory requirement that buyers have implemented an
        Environmental, Social and Governance (ESG) policy which seeks to reduce
        and eliminate their scope 1, 2 and 3 emissions. Following your purchase
        of credits, we will reach out and request a copy of your ESG policy.
      </p>
      <br />

      <p className="font-bold">First In, First Out</p>
      <p className="mt-1">
        Hemp Carbon Standard Credits (HCSC) are offered on a first in, first out
        basis (FIFO), to ensure that all farmers are treated equally, and that
        credits are sold in the order that they’re generated. So, if a supplier
        entered the marketplace in June, their credits were sold before a
        supplier who entered the marketplace in September. This commoditizes the
        removal of C02 and removes the costly matchmaking process that occurs in
        traditional carbon offset markets.
      </p>
      <br />

      <p className="font-bold mt-1">
        Refunds, Returns, and Cancellation Policy
      </p>
      <p className="mt-1">
        We do not entertain returns, exchanges, or refunds. All transactions are
        definitive and final.
      </p>
      <br />

      <p className="font-bold">Delivery Policy</p>
      <p className="mt-1">
        Upon your purchase of a Hemp Carbon Standard Credit (HCSC), the
        retirement of the HCSC and the issuance of your respective certificate
        might take up to 6 months. If you need a Proof of Purchase certificate
        from Hemp Carbon Standard, you can request one by emailing
        sales@hempcarbonstandard.com, and you'll receive your certificate within
        2-5 business days.
      </p>
      <p className="mt-1">
        Once the HCSCs you've acquired have been retired, the email address used
        for the purchase will be sent the HCSC certificate. The retirement of
        the HCSCs will be recorded on the blockchain. A retired HCSU cannot be
        reactivated or resold.
      </p>
      <br />
      <p className="font-bold">Payment Methods Accepted</p>
      <p className="mt-1">
        For purchases up to 10,000 HCSCs, we accept MasterCard, Visa, American
        Express, and Discover. For larger orders, bank transfers are acceptable.
      </p>
      <br />
      <p className="font-bold">Quantification Guarantee</p>
      <p className="mt-1">
        As detailed in How Hemp Carbon Standard Works, our guarantee to buyers
        is that 1 Hemp Carbon Standard Credit (HCSC) represents 1 tonne of
        carbon dioxide-equivalent (tCO2e), +/- 10%, removed from the atmosphere.
        If a Hemp Carbon Standard audit discovers that less CO2 was drawn down
        from the atmosphere than HCSCs issued, Hemp Carbon Standard will procure
        and decommission enough new HCSCs to cover any audit-identified
        deficiency.
      </p>
    </div>
  );
}
