import { Route, Routes } from "react-router-dom";

import BuyCarbonCredite from "../Pages/buyCarbonCredit";
import Detail from "../Pages/detail";
import Confirm from "../Pages/confirm";
import Policy from "../Pages/policy";

export default function Routess() {
  return (
    <Routes>
      <Route path="/" element={<BuyCarbonCredite />} />
      <Route path="/detail" element={<Detail />} />
      <Route path="/confirm" element={<Confirm />} />
      <Route path="/policy" element={<Policy />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
}
